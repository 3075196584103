.dashboard__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.dashboard__form {
  width: 100%;
}

.password-input {
  position: relative;
}

.toggle-password-btn {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  border: none;
  background: none;
  color: #999;
  cursor: pointer;
}

.dashboard__homeLink {
  position: absolute;
  bottom: 10px;
}
