.swiper-wrapper {
  padding-bottom: 3rem;
}

.swiper-pagination-bullet {
  background: var(--color-primary-variant) !important;
  opacity: 0.6;
}

.swiper-pagination-bullet-active {
  background: var(--color-bg-variant) !important;
  opacity: 1;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-bg-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* medium width devices */
@media (max-width: 1024px) {
  /* .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  } */
}

/* small width devices */
@media (max-width: 600px) {
  /* .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  } */
}
